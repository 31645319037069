import { BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Mobilepay({ size, title }: ComponentProps) {
  return (
    <>
      <BaseSvgStyle
        size={size}
        viewBox="0 0 35 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{title ? title : ''}</title>
        <g id="Payment Method/Visa">
          <rect
            id="BASE"
            x="0.5"
            y="0.5"
            width="34"
            height="23"
            rx="3.5"
            fill="white"
            stroke="#D9D9D9"
          />
          <g id="Group">
            <path
              id="Vector"
              d="M16.0225 20C15.4752 20 14.9817 19.6705 14.772 19.1649L10.1032 7.89418C9.8176 7.20456 10.145 6.41396 10.8346 6.12823L15.7247 4.10294C16.0558 3.96572 16.4279 3.96569 16.7591 4.10285C17.0902 4.24002 17.3533 4.50315 17.4904 4.83433L22.1592 16.1055C22.4444 16.795 22.117 17.5851 21.4278 17.8708L16.5377 19.897C16.3744 19.9649 16.1994 19.9999 16.0225 20ZM16.242 4.72716C16.16 4.72729 16.0789 4.74361 16.0033 4.77518L11.113 6.80047C10.9601 6.86379 10.8387 6.98524 10.7754 7.13812C10.7121 7.29099 10.7121 7.46275 10.7755 7.61559L15.4442 18.887C15.5764 19.2051 15.941 19.3561 16.2594 19.2248L21.1494 17.1981C21.3023 17.1348 21.4237 17.0133 21.487 16.8604C21.5503 16.7076 21.5503 16.5358 21.4869 16.383L16.8182 5.11269C16.7215 4.87965 16.4942 4.72758 16.242 4.72716Z"
              fill="#5A78FF"
            />
            <path
              id="Vector_2"
              opacity="0.7"
              d="M17.7992 5.577L17.127 5.85547L18.9755 10.3182L19.6478 10.0398L17.7992 5.577Z"
              fill="url(#paint0_linear_2992_131935)"
            />
            <path
              id="Vector_3"
              opacity="0.7"
              d="M21.1754 15.6339L21.8477 15.3555L19.7552 10.3038L19.0829 10.5823L21.1754 15.6339Z"
              fill="url(#paint1_linear_2992_131935)"
            />
            <path
              id="Vector_4"
              d="M20.3274 12.2853C18.9289 12.8652 17.7612 13.6645 16.9966 14.6281L15.0254 9.86726C15.9246 8.82259 17.0691 8.01755 18.3562 7.52441C19.7547 6.94449 21.1676 6.66382 22.368 6.82735L24.3397 11.588C22.9652 11.4845 21.5865 11.7241 20.3274 12.2853Z"
              fill="#5A78FF"
            />
            <g id="Group_2" /*style="mix-blend-mode:multiply"*/ opacity="0.4">
              <path
                id="Vector_5"
                opacity="0.9"
                d="M20.3274 12.2853C18.9289 12.8652 17.7612 13.6645 16.9966 14.6281L15.0254 9.86726C15.9246 8.82259 17.0691 8.01755 18.3562 7.52441C19.7547 6.94449 21.1676 6.66382 22.368 6.82735L24.3397 11.588C22.9652 11.4845 21.5865 11.7241 20.3274 12.2853Z"
                fill="url(#paint2_linear_2992_131935)"
              />
              <path
                id="Vector_6"
                opacity="0.7"
                d="M20.3274 12.2853C18.9289 12.8652 17.7612 13.6645 16.9966 14.6281L15.0254 9.86726C15.9246 8.82259 17.0691 8.01755 18.3562 7.52441C19.7547 6.94449 21.1676 6.66382 22.368 6.82735L24.3397 11.588C22.9652 11.4845 21.5865 11.7241 20.3274 12.2853Z"
                fill="url(#paint3_linear_2992_131935)"
              />
            </g>
            <path
              id="Vector_7"
              d="M20.9697 13.7367C19.4559 13.7367 18.0701 14.0292 16.9961 14.6263V9.81251C18.2265 9.1916 19.5919 8.88592 20.9697 8.92292C22.4835 8.92292 23.8964 9.2036 24.943 9.81367V14.6274C23.7128 14.006 22.3474 13.6999 20.9697 13.7367Z"
              fill="#5A78FF"
            />
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_2992_131935"
            x1="190.388"
            y1="331.46"
            x2="160.206"
            y2="-20.6753"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#504678" />
            <stop offset="0.302" stopColor="#504678" stopOpacity="0.616" />
            <stop offset="0.608" stopColor="#504678" stopOpacity="0.283" />
            <stop offset="0.852" stopColor="#504678" stopOpacity="0.076" />
            <stop offset="1" stopColor="#504678" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2992_131935"
            x1="-169.954"
            y1="-355.008"
            x2="-151.025"
            y2="39.5551"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#504678" />
            <stop offset="0.302" stopColor="#504678" stopOpacity="0.616" />
            <stop offset="0.608" stopColor="#504678" stopOpacity="0.283" />
            <stop offset="0.852" stopColor="#504678" stopOpacity="0.076" />
            <stop offset="1" stopColor="#504678" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_2992_131935"
            x1="142.651"
            y1="527.35"
            x2="512.03"
            y2="275.538"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#504678" />
            <stop offset="0.179" stopColor="#504678" stopOpacity="0.872" />
            <stop offset="0.526" stopColor="#504678" stopOpacity="0.536" />
            <stop offset="1" stopColor="#504678" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_2992_131935"
            x1="459.543"
            y1="281.194"
            x2="443.159"
            y2="150.148"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#504678" />
            <stop offset="0.643" stopColor="#504678" stopOpacity="0.332" />
            <stop offset="1" stopColor="#504678" stopOpacity="0" />
          </linearGradient>
        </defs>
      </BaseSvgStyle>
    </>
  );
}

export default Mobilepay;
